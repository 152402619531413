<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useProjectsStore } from "@/stores/db/projects";
import type { IUserForms, IVGuestForm } from "~/types";
import type { IUser } from "~/stores/db/users";
const { leftMenu } = storeToRefs(useUIStore());

definePageMeta({
    layout: 'dashboard-fluid',
    auth: false
})
const { data: sessionData } = useAuth()
const loggedIn = computed(() => useAuth().data.value?.accessToken ? true : false)
interface IRedmineResponse {
    success: boolean;
    issueId: number;
}
const tasks = ref<IUserForms[]>([])
// data
const nuxtApp = useNuxtApp()
const router = useRouter()
const requestUrl = useRequestURL()
const loading = ref(true)
const { currentProject, userProjects, userFormsByProject } = storeToRefs(
    useProjectsStore()
);
const { fetchUserFormsByProject } = useProjectsStore();

// console.log('reeee', requestUrl.hostname)

watchEffect(async () => {
    if (currentProject.value && userProjects.value.length > 0)
        if (typeof currentProject.value.id === "number") {
            await fetchUserFormsByProject();
        } else {
            cl(
                "AppRightDrawer.watchEffect",
                `NOT CURRENT PROJECT?: ${JSON.stringify(currentProject.value)}`
            );
        }
});


const findGuestFormByDomain = async () => {
    const { data, error } = await useCustomFetch<IVGuestForm>(`guest-forms/domain/${requestUrl.hostname}`, {
        pick: ['id', 'domain', 'formId', 'projectId']
    });
    cl('tasks', `result: ${JSON.stringify(data.value)}`)
    return data.value

}


const init = async () => {
    // cl('tasks.init', 'begin...')
    loading.value = true;
    if (loggedIn.value) {
        cl('tasks.init', 'user is logged in. show forms')
        const { data: user } = await useCustomFetch<IUser>(`/users/${useAuth().data.value.id}`, {
            pick: ["id", "defaultFormId"],
        });
        // cl('tasks.init', `user: ${JSON.stringify(user.value)}`)
        if (user.value?.defaultFormId) {

            cl('tasks.init', `navigate to default form ${user.value?.defaultFormId}`)
            navigateTo(`/dispatch/${user.value?.defaultFormId}`);

        } else {
            await fetchUserFormsByProject()
            tasks.value = userFormsByProject.value as IUserForms[]
            cl('tasks.init', `not defaultFormId ${tasks.value.length} ${userFormsByProject.value?.length}`)
        }

        loading.value = false;
        return
    } else {
        // cl('tasks.init', 'guest mode')
    }
    const guestForm = await findGuestFormByDomain()
    if (guestForm) {
        cl('tasks.init', `guestForm: ${JSON.stringify(guestForm)}`)
        // cl('tasks.init', `currentProject: ${JSON.stringify(currentProject.value)}, ${guestForm.projectId}`)
        if (guestForm.formId) {
            nuxtApp.runWithContext(() => navigateTo(`/dispatch/${guestForm.formId}`))
        } else {
            cl('tasks.init', `load all guest forms by project: ${guestForm.projectId}`)
            // if (!guestForm.projectId || guestForm.projectId.value === undefined) {
            //     cl('tasks.init', `!guestForm.projectId || guestForm.projectId.value === undefined`)
            //     return []
            // }
            const { data, error } = await useCustomFetch<IUserForms[]>(`users/forms/projects/${guestForm.projectId}/all-guest-forms`);
            tasks.value = data.value as IUserForms[]
            loading.value = false;
        }
        // navigateTo(`/dispatch/${guestForm.formId}`)
    } else { // show all guest forms
        cl('tasks.init', `guestForm not found. redirect ro /auth/login`)
        nuxtApp.runWithContext(() => navigateTo(`/auth/login`))
    }
    loading.value = false;
}

await init()

if (!sessionData.value?.roles.includes('admin'))
    leftMenu.value = true
if (!loggedIn) leftMenu.value = true

cs(`pages.tasks`);


</script>

<template>
    <div>
        <!-- <pre>t {{ tasks }}</pre>
        <pre>p {{ userFormsByProject }}</pre> -->
        <v-container v-if="loading">
            <v-row>
                <v-col v-for="n in 3"
                       :key="n"
                       cols="12"
                       md="6"
                       lg="4">
                    <v-skeleton-loader :loading="loading"
                                       height="240"
                                       type="card">
                    </v-skeleton-loader>
                </v-col>
            </v-row>

            <br>

        </v-container>
        <v-row v-else>
            <v-col v-if="tasks"
                   cols="12"
                   md="6"
                   lg="4"
                   v-for="item in tasks"
                   :key="item.form_id">

                <v-hover v-slot="{ isHovering, props }">
                    <AppLink :to="item.form_link"
                             class="text-decoration-none text-grey-darken-4">
                        <v-card :elevation="isHovering ? 8 : 2"
                                v-bind="props"
                                :prepend-icon="item.form_icon ? item.form_icon : 'mdi-checkbox-blank-outline'"
                                :title="item.form_title">
                            <v-card-text v-html="item.form_note" />
                        </v-card>
                    </AppLink>
                </v-hover>
            </v-col>
            <v-col v-else
                   cols="12">
                <div class="d-flex justify-center   text-h3">
                    <p>Нет доступных форм</p>
                </div>
            </v-col>
        </v-row>
    </div>
</template>


<style scoped></style>